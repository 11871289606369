import { layoutConfig } from "@/config/_layoutConfig";
import { apiConfig } from "@/config/_apiConfig";
import { webSocketConfig } from "@/config/_webSocketConfig";
import { projectConfig } from "@/config/_projectConfig";
import { chatConfig } from "@/config/_chatConfig";
import { tasksConfig } from "@/config/_tasksConfig";
import { filesystemConfig } from "@/config/_filesystemConfig";
import { spaceConfig } from "@/config/_spaceConfig";
export * from '@/config/_canvasConfig'
import { dashboardConfig }  from "@/config/_dashboardConfig";
import {logHistoryConfig} from "@/config/_logHistoryConfig"


const DEFAULT_GRID_VIEWS = {
  dashboard: {
    gridView: projectConfig.project.gridView.thumbnail[0],
    isGrid: true,
  },
  filesByProjects: {
    gridView: projectConfig.project.gridView.thumbnail[0],
    isGrid: true,
  },
  filesByVersions: {
    gridView: projectConfig.project.gridView.thumbnail[0],
    isGrid: true,
  },
  filesByStyles: {
    gridView: projectConfig.project.gridView.thumbnail[0],
    isGrid: true,
  },
  design: {
    gridView: projectConfig.project.gridView.list[0],
    isGrid: false,
  }
}
export default {
  storage: {
    storageGridKey: 'gridView'
  },
  appName: process.env.VUE_APP_NAME,
  theme: {
    dark: 'dark',
    light: 'light',
  },
  user: {
    role: {
      admin: 'admin',
      manager: 'manager',
      client: 'client',
      reviewer: 'reviewer',
      watcher: 'watcher',
    }
  },
  auth: {
    provider: {
      google: 'google',
      linkedin: 'linkedin',
      facebook: 'facebook'
    }
  },
  defaultGridViews: DEFAULT_GRID_VIEWS,

  ...layoutConfig,
  ...apiConfig,
  ...webSocketConfig,
  ...projectConfig,
  ...chatConfig,
  ...tasksConfig,
  ...filesystemConfig,
  ...spaceConfig,
  ...logHistoryConfig,
  dashboardConfig
}
