<template>
  <div>
    <div class="filters-wrap d-flex align-center justify-space-between px-6 py-3 relative">
      <div class="d-flex align-center pl-1">
        <template v-for="(member, idx) in getProject.team">
          <v-tooltip color="accent" top :key="member.user.id">
            <template v-slot:activator="{ on, attrs }">
              <UiAvatar
                v-if="idx < 4"
                v-bind="attrs" v-on="on"
                :key="member.user.id"
                :text="member.user.first_name"
                :title="`${member.user.first_name || ''} ${member.user.last_name || ''}`"
                size="20"
                :class="['ml-n1', {
                'accent': idx === 0,
                'accent-60': idx === 1,
                'accent-100': idx === 2,
                'gray-100': idx === 3,
              }]"
              />
            </template>
            <span>{{ member.user.first_name }} {{ member.user.last_name }}</span>
          </v-tooltip>
        </template>
        <UiAvatar v-if="getProject.team.length > 4" :text="`${getProject.team.length - 4}`" size="20" class="ml-n1"
                  color="gray-60"/>
      </div>

      <div class="d-flex align-center">
        <UiFilter
          :filterData="filtersByTab"
          offset-y
          allow-overflow
          clearBtn
          max-height="70vh"
          @setFilters="updateFiltersHandler"
        />

        <UiSort
          v-if="getProjectSideDrawer.tabs !== 'tasks'"
          :list="sortOptions"
          :value1="sortValue"
          mandatory1
          @setSort1="setSort"
          class="ml-3"
        />

        <Search @onSearch="setSearch" class="ml-4"/>
      </div>
    </div>

    <v-list class="d-flex flex-wrap pt-0 pb-1 px-6">
      <v-list-item
        v-for="chip in filterChips"
        :key="`item-${chip.category_type}-${chip.id}`"
        :value="chip.id"
        :ripple="false"
        class="chips-item d-flex flex-grow-0 flex-basis-auto mr-2 mb-2 rounded"
      >
        <v-list-item-content class="pa-0">
          <v-list-item-title class="d-flex align-center text-captions-2 text-wrap" :title="chip.title">
            {{ chip.title }}
            <button type="button" @click="removeFilterChip(chip)">
              <IconCancelCircle width="12" class="ml-1" style="margin-top: 3px;"/>
            </button>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="getProjectSideDrawerFilters.search"
        :ripple="false"
        class="chips-item d-flex flex-grow-0 flex-basis-auto mr-2 mb-2 rounded"
      >
        <v-list-item-content class="pa-0">
          <v-list-item-title class="d-flex align-center text-captions-2 text-wrap"
                             :title="getProjectSideDrawerFilters.search">
            {{ getProjectSideDrawerFilters.search }}
            <button type="button" @click="setSearch('')">
              <IconCancelCircle width="12" class="ml-1" style="margin-top: 3px;"/>
            </button>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiAvatar from "@/components/UI/UiAvatar";
import UiFilter from "@/components/UI/UiFilter";
import UiSort from "@/components/UI/UiSort";
import Search from "./Search";

export default {
  name: 'Filters',
  components: {
    IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),

    UiAvatar,
    UiFilter,
    UiSort,
    Search,
  },
  data() {
    return {
      sortValue: {
        name: 'Ascending',
        slug: 'asc'
      },
      sortOptions: [
        {
          name: 'Ascending',
          slug: 'asc'
        },
        {
          name: 'Descending',
          slug: 'desc'
        }
      ],
      filterData: [],
      eventTypes: [
        {id: this.$config.chat.messageTypes.newFileAdded, title: 'Upload file'},
        {id: this.$config.chat.messageTypes.fileDeleted, title: 'Delete'},
        {id: this.$config.chat.messageTypes.styleDeleted, title: 'Archive'},
      ],
      fileTypes: [
        {id: 'JPG', title: 'JPG'},
        {id: 'JPEG', title: 'JPEG'},
        {id: 'PNG', title: 'PNG'},
        {id: 'GIF', title: 'GIF'},
        {id: 'PDF', title: 'PDF'},
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getDesignModules',
      'getAllProjectStyles',
      'getProjectSideDrawer',
      'getProjectSideDrawerFilters',
      'getProjectSideDrawerDefaultFilters',
      'showProjectSideDrawer'
    ]),
    filtersByTab() {
      return this.filterData.filter(filterCategory => this.getProjectSideDrawer.filtersByTab[this.getProjectSideDrawer.tabs]?.includes(filterCategory.type)) || []
    },
    filterChips() {
      return this.filtersByTab.reduce((filterChips, category) => {
        if (category.activeFilters !== null && !Array.isArray(category.activeFilters)) {
          filterChips.push(...category.list.reduce((acc, item) => {
            if (item.id === category.activeFilters) {
              acc.push({...item, category_type: category.type})
            }
            return acc;
          }, []));
        } else if (Array.isArray(category.activeFilters) && category.activeFilters.length) {
          filterChips.push(...category.list.reduce((acc, item) => {
            if (category.activeFilters.includes(item.id)) {
              acc.push({...item, category_type: category.type})
            }
            return acc;
          }, []));
        }
        return filterChips;
      }, []);
    },
  },
  watch: {
    showProjectSideDrawer: {
      handler(newVal) {
        if(newVal) {
          this.initFilters()
        }
      },
      immediate: true
    },
    // when user creates new style we must update style list in filters
    'getAllProjectStyles': {
      handler(newVal) {
        this.filterData = this.filterData.map(category => {
          if (category.type === 'project_style_id') {
            return {
              ...category,
              list: newVal,
            }
          }
          return category
        })
      }
    },
    // when user creates new tag we must update tag list in filters
    'getProject.tag': {
      handler(newVal) {
        this.filterData = this.filterData.map(category => {
          if (category.type === 'project_tag_id') {
            return {
              ...category,
              list: newVal,
            }
          }
          return category
        })
      }
    },
    getProjectSideDrawerFilters: {
      handler(val) {
        this.setSortValueFromStore(val.order)
      },
      deep: true,
    }
  },
  created() {
    this.checkTagAndStyleConnection(this.filterData);
  },
  methods: {
    removeFilterChip(chip) {
      const newFilterData = this.filterData.map(category => {
        if (category.type === chip.category_type) {
          return {
            ...category,
            activeFilters: Array.isArray(category.activeFilters) ? category.activeFilters.filter(filter => filter !== chip.id) : null
          }
        }
        return category;
      });
      this.updateFiltersHandler(newFilterData);
    },
    checkTagAndStyleConnection(data) {
      // we have to check each tag & style and disable it if they aren't related
      // this.filterData = data.map(category => {
      //   if (category.type === 'project_tag_id') {
      //     const styleCategory = data.find(cat => cat.type === 'project_style_id');
      //     const selectedStyle = styleCategory.list.find(style => style.id === styleCategory.activeFilters);
      //     return {
      //       ...category,
      //       list: category.list.map(tag => ({
      //         ...tag,
      //         disabled: selectedStyle ? tag.id !== selectedStyle.tags[0].id : false
      //       }))
      //     }
      //   }
      //   if (category.type === 'project_style_id') {
      //     const selectedTag = data.find(cat => cat.type === 'project_tag_id').activeFilters;
      //     return {
      //       ...category,
      //       list: category.list.map(style => ({
      //         ...style,
      //         disabled: selectedTag ? style.tags[0].id !== selectedTag : false
      //       }))
      //     }
      //   }
      //   return category;
      // })

      this.filterData = this.filterData.map(category => {
        const selectedCategory = data.find(cat => cat.type === category.type);
        if (selectedCategory && selectedCategory.type === 'project_tag_id') {
          const styleCategory = data.find(cat => cat.type === 'project_style_id');
          const selectedStyle = styleCategory.list.find(style => style.id === styleCategory.activeFilters);
          return {
            ...selectedCategory,
            list: selectedCategory.list.map(tag => ({
              ...tag,
              disabled: selectedStyle ? tag.id !== selectedStyle.tags[0].id : false
            }))
          }
        } else if (selectedCategory && selectedCategory.type === 'project_style_id') {
          const selectedTag = data.find(cat => cat.type === 'project_tag_id').activeFilters;
          return {
            ...selectedCategory,
            list: selectedCategory.list.map(style => ({
              ...style,
              disabled: selectedTag ? style.tags[0].id !== selectedTag : false
            }))
          }
        } else if(selectedCategory) {
          return selectedCategory
        }
        return category;
      })
    },
    updateFiltersHandler(data) {
      const newFilters = {};
      data.forEach(filterCategory => {
        newFilters[filterCategory.type] = filterCategory.activeFilters !== undefined ? filterCategory.activeFilters : this.getProjectSideDrawerDefaultFilters[filterCategory.type];
      });

      this.filterData = this.filterData.map(filterCategory => {
        const updatedFilterCategory = data.find(fCategory => fCategory.type === filterCategory.type);
        return updatedFilterCategory || filterCategory;
      });

      this.setFilters(newFilters);
    },
    setFilters(data) {
      this.$eventBus.$emit('updateSidDrawerFilters', data);
      this.$store.dispatch('setProjectSideDrawerFilters', data);
    },
    setSearch(value) {
      this.setFilters({search: value});
    },
    setSort(sortOrder) {
      this.setFilters({order: sortOrder.slug});
    },
    mapData(dataKey, dataLabels) {
      return Object.values(this.$config.tasks[dataKey]).reduce((acc, item) => {
        acc.push({id: item, title: this.$config.tasks[dataLabels][item]});
        return acc;
      }, [])
    },
    initFilters() {
      this.filterData = [
        {
          type: 'project_style_id',
          title: 'Styles',
          list: this.getAllProjectStyles,
          isMultiple: false,
          activeFilters: this.getProjectSideDrawerFilters.project_style_id
        },
        {
          type: 'project_module',
          title: 'Design Module',
          list: this.getDesignModules,
          isMultiple: false,
          activeFilters: this.getProjectSideDrawerFilters.project_module
        },
        {
          type: 'assignee',
          title: 'Assignee',
          list: this.getProject.team.map(user => ({
            id: user.user.id,
            title: `${user.user.first_name} ${user.user.last_name || ''}`
          })),
          isMultiple: true,
          activeFilters: this.getProjectSideDrawerFilters.assignee
        },
        {
          type: 'owner',
          title: 'Owner',
          list: this.getProject.team.map(user => ({
            id: user.user.id,
            title: `${user.user.first_name} ${user.user.last_name || ''}`
          })),
          isMultiple: true,
          activeFilters: this.getProjectSideDrawerFilters.owner
        },
        {
          type: 'priority',
          title: 'Priority',
          list: this.mapData('priority', 'priorityLabels'),
          isMultiple: false,
          activeFilters: this.getProjectSideDrawerFilters.priority
        },
        {
          type: 'status',
          title: 'Status',
          list: this.mapData('status', 'statusLabels'),
          isMultiple: false,
          activeFilters: this.getProjectSideDrawerFilters.status
        },
        {
          type: 'event_type',
          title: 'Event Type',
          list: this.eventTypes,
          isMultiple: false,
          activeFilters: this.getProjectSideDrawerFilters.event_type
        },
        {
          type: 'file_type',
          title: 'File Type',
          list: this.fileTypes,
          isMultiple: true,
          activeFilters: this.getProjectSideDrawerFilters.file_type
        },
      ]
      if (this.getProject.tag.length) {
        this.filterData.unshift({
          type: 'project_tag_id',
          title: 'Tags',
          list: this.getProject.tag,
          isMultiple: false,
          activeFilters: this.getProjectSideDrawerFilters.project_tag_id
        })
      }
    },
    setSortValueFromStore(order) {
      if(order && this.sortValue.slug !== order ) {
        this.sortValue = this.sortOptions.find(option => option.slug === order);
      }
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .chips-item {
  min-height: 18px;
  padding: 0 6px;
  border: 1px solid var(--v-accent-base);
  background-color: var(--v-accent-10-base);

  &::before {
    display: none;
  }

  .v-list-item__title {
    color: var(--v-accent-base);
  }
}
</style>
