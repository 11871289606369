<template>
  <v-navigation-drawer
    v-model="$store.state.Notifications.showNotificationBar"
    fixed
    right
    temporary
    touchless
    hide-overlay
    width="100%"
    class="notifications-bar gray-0"
  >

    <div class="d-flex align-center justify-space-between accent py-5 px-6">
      <div class="d-flex align-center font-weight-medium text-body-lg gray-0--text">
        <!-- <IconBell width="18" class="mr-5"/> -->
        <component :is="settings.icon" width="18" class="mr-5"/>

        <span class="mr-4">{{ settings.title }}</span>

        <UiSort
          class="mr-6"
          mandatory1
          :list="sortList1"
          :value1="$store.state.Notifications[settings.sortStoreKey]"
          :min-width="106"
          @setSort1="value => this.setSort('sort_1', value)"
        >
          <template #icon>
            <span class="font-weight-regular text-captions-1  gray-0--text">{{ $store.state.Notifications[settings.sortStoreKey].name }}</span>
          </template>
          <template #sub_icon="{active, menu}">
            <IconChevronDown v-if="active || menu" width="8" :class="['ml-2 icon-transition gray-0--text', {'rotate-180': menu}]"/>
          </template>
          <template #bottom-action>
            <div class="px-2 pb-2 d-flex">
              <UiBtn class="mark-all-as-read" text @click="markAllAsRead">
                <span class="text-decoration-underline font-weight-regular">Mark all as read</span>
              </UiBtn>
            </div>
          </template>
        </UiSort>
      </div>
      <UiBtn icon color="gray-0" @click="$store.dispatch('toggleNotificationBar', {open: false})">
        <IconCancel width="18"/>
      </UiBtn>
    </div>

    <div v-if="loading" class="d-flex justify-center align-center fill-height">
      <v-progress-circular :size="100" color="accent" indeterminate/>
    </div>

    <v-list v-else flat class="notifications-list absolute pt-6 pl-3 pr-4 overflow-y-auto">
      <v-list-item
        v-for="notification in notifications"
        :key="notification.id"
        :ripple="false"
        :class="['notifications-item align-start py-2 pl-7 pr-5 mb-4', {'is-unread': !notification.read_at}]"
        @click="readNotification(notification)"
      >
        <div class="notifications-item-avatar relative flex-shrink-0 mr-4">
          <v-tooltip v-if="notification.user" top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <UiAvatar
                v-bind="attrs" v-on="on"
                :src="notification.user.avatar ? notification.user.avatar.url : ''"
                :text="notification.user.first_name"
                size="30"
              />
            </template>
            <span class="white--text font-weight-medium">{{ notification.user.first_name }} {{ notification.user.last_name }}</span>
          </v-tooltip>
          <div v-else class="d-flex justify-center align-center gray-10 rounded-circle" style="width: 30px;height: 30px;">
            <IconSettings width="18" class="gray-60--text"/>
          </div>
        </div>

        <div class="mt-1 mr-12">
          <p class="notification-message mb-2 text-body gray-100--text" v-html="notification.message" @click="keywordAction($event, notification)"/>
          <div v-if="notification?.additional_data?.comment" class="d-flex">
            <div class="d-inline message-comment pl-3 mt-2 mb-2 body-2 gray-100--text font-weight-semi-bold" v-html="notification.additional_data.comment" @click="go(notification)"></div>
          </div>
          <div class="d-flex align-center text-captions-1">
<!--            <span v-if="notification.additional_data.project_name" class="mr-1 gray-60&#45;&#45;text">-->
<!--              {{ notification.additional_data.project_name }}-->
<!--            </span>-->
<!--            <span v-if="notification.additional_data.file_name" class="mr-1 gray-60&#45;&#45;text">-->
<!--              | {{ notification.additional_data.file_name }}-->
<!--            </span>-->
            <span class="gray-60--text">{{ formatDate(notification.created_at) }}</span>
          </div>
        </div>

        <div v-if="notification.link" @click="go(notification)" class="flex-shrink-0 mt-1 ml-auto accent--text">
          <IconExtLink width="16"/>
        </div>
      </v-list-item>

      <v-list-item v-if="getNotifications(settings.type).nextLink" class="justify-center">
        <div v-if="loadingMore" class="d-flex justify-center align-center pa-2">
          <v-progress-circular :size="32" color="accent" indeterminate/>
        </div>
        <UiBtn
          v-else
          text
          color="accent"
          class="text-none"
          @click="loadMore"
        >
          See more incoming notification
        </UiBtn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiAvatar from "@/components/UI/UiAvatar";
import UiSort from "@/components/UI/UiSort";
import {formatDistance} from "date-fns";
import notificationLinkMixin from "@/mixins/notificationLinkMixin";

export default {
  name: 'NotificationBar',
  mixins: [notificationLinkMixin],
  components: {
    IconBell: () => import('@/components/icons/IconBell'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconSettings: () => import('@/components/icons/IconSettings'),
    IconExtLink: () => import('@/components/icons/IconExtLink'),
    IconChat: () => import("@/components/icons/IconChat"),
    UiBtn,
    UiAvatar,
    UiSort,
  },
  data() {
    return {
      loading: false,
      loadingMore: false,
      sortList1: [
        {name: 'All', slug: 'all'},
        {name: 'Unread', slug: 'unread'},
      ],
      sort_1: {name: 'All', slug: 'all'},
    }
  },
  computed: {
    ...mapGetters([
      'getNotifications',
      'getNotificationBarType'
    ]),
    notifications() {
      return this.getNotifications(this.settings.type).data.map(notification => ({
        ...notification,
        additional_data: {
          project_id: notification.additional_data?.project_id || null,
          project_name: notification.additional_data?.project_name || null,
          module_id: notification.additional_data?.module_id || null,
          tag_id: notification.additional_data?.tag_id || null,
          style_id: notification.additional_data?.style_id || null,
          file_id: notification.additional_data?.file_id || null,
          comment_id: notification.additional_data?.comment_id || null,
          task_id: notification.additional_data?.task_id || null,
          file_name: notification.additional_data?.file_name || null,
          open_review: notification.additional_data?.open_review || null,
          comment: notification.additional_data?.comment || null,
          gallery_group_id: notification.additional_data?.gallery_group_id || null,
        },
        link: this.setNotificationsLink(notification),
      }))
    },
    settings() {
      if(this.getNotificationBarType === this.$config.notifications.notificationType.notifications) {
        return {
          title: 'Notifications',
          icon: 'IconBell',
          type: this.$config.notifications.notificationType.notifications,
          sortStoreKey: 'notificationBarSort'
        }
      }
      return {
        title: 'Messages',
        icon: 'IconChat',
        type: this.$config.notifications.notificationType.messages,
        sortStoreKey: 'messagesBarSort'
      }
    }
  },
  async created() {
    await this.$store.dispatch('setNotifications', {data: [], nextLink: null});
    await Promise.all([this.loadNotifications(null, this.$config.notifications.notificationType.messages), this.loadNotifications(null, this.$config.notifications.notificationType.notifications)]);
  },
  methods: {
    formatDate(date) {
      return formatDistance(new Date(date), new Date(), {addSuffix: true}).replace('about ', '');
    },
    async loadNotifications(url, type = this.settings.type) {
      const notifications = await this.$api.notification.list(url, {sort_by: this.$store.state.Notifications[this.settings.sortStoreKey].slug, type: type});
      const previousNotification = url ? this.getNotifications(this.settings.type).data : [];
      return await this.$store.dispatch('setNotifications', {
        data: [...previousNotification, ...notifications.data],
        nextLink: notifications.links.next,
        type: type
      });
    },
    async loadMore() {
      this.loadingMore = true;
      await this.loadNotifications(this.getNotifications(this.settings.type).nextLink);
      this.loadingMore = false;
    },
    async setSort(key, value) {
      // this[key] = value;
      await this.$store.dispatch('setNotificationSort', {sort: value, type: this.settings.type});

      this.loading = true;
      await this.loadNotifications();

      this.loading = false;
    },
    async readNotification(notification) {
      if (notification.read_at) return;

      this.$store.dispatch('setNotifications', {
        data: this.getNotifications(this.settings.type).data.map(item => item.uuid === notification.uuid ? ({...item, read_at: new Date()}) : item),
        type: this.settings.type
      });
      try {
        await this.$api.notification.read(notification.uuid);
      } catch (error) {
        console.error(error);
      }
    },
    async go(notification) {
      if(!notification.link) {
        return;
      }
      if (notification.link.url) {
        await this.$router.push({path: notification.link.url});
      } else {
        await notification.link.callback();
      }
    },
    keywordAction($event, notification) {
      const path = $event.target.getAttribute('data-action');

      if (!path) return;

      this.$store.dispatch('toggleNotificationBar', {open: false});

      if (notification.type === 'design-request-review-design') {
        this.$store.dispatch('toggleNotificationBar', {open: false});
        this.$router.replace({
          name: 'ProjectDesign',
          params: {project_id: notification.additional_data.project_id},
          query: {openReview: notification.additional_data.open_review},
        });
        return;
      }

      switch (path) {
        case 'to_project':
          this.$router.replace({
            name: 'ProjectDesign',
            params: {project_id: notification.additional_data.project_id},
            query: {module: notification.additional_data.module_id}
          });
          break;
        case 'to_style':
          this.$router.replace({
            name: 'DesignViewPage',
            params: {project_id: notification.additional_data.project_id},
            query: {
              module_id: notification.additional_data.module_id,
              style_id: notification.additional_data.style_id,
            }
          });
          break;
        case 'to_version':
          this.$router.replace({
            name: 'DesignViewPage',
            params: {project_id: notification.additional_data.project_id},
            query: {
              module_id: notification.additional_data.module_id,
              style_id: notification.additional_data.style_id,
              file_id: notification.additional_data.file_id,
            }
          });
          break;
        case 'to_task':
          this.$router.replace({
            name: 'DesignViewPage',
            params: {project_id: notification.additional_data.project_id},
            query: this.$route.name === 'DesignViewPage' ? {
              ...this.$route.query,
              modal_task_id: notification.additional_data.task_id,
              style_id: notification.additional_data.style_id
            } : {modal_task_id: notification.additional_data.task_id, style_id: notification.additional_data.style_id}
          });
          break;
      }
    },
    async markAllAsRead() {
      try {
        const res = this.settings.type === this.$config.notifications.notificationType.notifications ? await this.$api.notification.readAll() : await this.$api.notification.readAllMessages()

        if(this.settings.type === this.$config.notifications.notificationType.notifications) {
          await this.loadNotifications(null, this.$config.notifications.notificationType.notifications)
        } else {
          await this.loadNotifications(null, this.$config.notifications.notificationType.messages)
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.notifications-bar {
  max-width: 546px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  z-index: 999 !important;

  .v-navigation-drawer__border {
    display: none;
  }

  .notifications-list {
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .notifications-item {
    min-height: 50px;
    &.is-unread {
      opacity: 1;

      .notifications-item-avatar::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid #2E90FF;
        background-color: #1DCA62;
      }
    }

    ::v-deep .notification-message {
      strong {
        font-weight: 700;
        color: var(--v-accent-base);
        border-bottom: 1px solid currentColor;
      }
    }
  }
}
.message-comment {
  position: relative;
  gap: 4px;
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    border-radius: 2px;
    width: 4px;
    height: 100%;
    background-color: var(--v-accent-base);
  }

}
.mark-all-as-read {
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin-top: -6px;
}
</style>
