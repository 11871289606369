export default axios => ({

  /**
   * Get notifications list
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Notifications-GetApiV1UsersNotification
   * @param url
   * @param params
   * @returns [{*}]
   */
  list(url, params) {
    if(!url) {
      url = '/users/notification'
    }
    return axios.get(url, {params});
  },

  /**
   * Read notification
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Notifications-PutApiV1UsersNotificationUuid
   * @param uuid
   */
  read(uuid) {
    return axios.put(`/users/notification/${uuid}`);
  },

  /**
   * Read all notification
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Notifications-PutApiV1UsersNotification
   */
  readAll() {
    return axios.put(`/users/notification`);
  },

  /**
   * Get projects notifications
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Notifications-PutApiV1UsersNotificationProjects
   * @returns [{*}]
   */
  getProjectsNotifications() {
    return axios.get('/users/notification/projects')
  },

  /**
   * Save projects notifications
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-PutApiV1ProjectsIdSettings
   */
  saveProjectsNotifications(projectId, data) {
    return axios.put(`projects/${projectId}/settings`, data)
  },

  /**
   * Push notification subscription   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Notifications-PostApiV1UsersNotificationSubscriptions
   * @param data
   */
  subscribePushNotification(data) {
    return axios.post('/users/notification/subscriptions', data)
  },

  /**
   * Get Global Notification Settings   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Notifications-GetApiV1UsersNotificationGlobal
   * @param data
   */
  getGlobalNotificationsSetting() {
    return axios.get('/users/notification/global')
  },

  /**
   * Get Global Notification Settings   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Notifications-GetApiV1UsersNotificationGlobal
   * @param data
   */
  updateGlobalNotificationsSetting(data) {
    return axios.patch('/users/notification/global', data)
  },

  /**
   * Read all messages
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Notifications-PutApiV1UsersNotificationsMessages
   */
  readAllMessages() {
    return axios.put(`/users/notification/messages`);
  },

  /**
   * Get spaces notifications
   *
   * @link https://devapi2.upsway.io/apidoc/index.html
   * @returns [{*}]
   */
  getSpacesNotifications() {
    return axios.get('/users/notification/spaces')
  },

  /**
   * Save spaces notifications
   *
   * @link https://devapi2.upsway.io/apidoc
   */
  saveSpacesNotifications(data) {
    return axios.put(`spaces/multiple/settings`, data)
  },
})
