import {chatConfig} from "@/config/_chatConfig"
export default {

  /**
   * @param commit
   * @param payload
   */
  toggleNotificationBar({commit}, payload) {
    commit('TOGGLE_NOTIFICATION_BAR', payload.open)
    if(payload.type) {
      commit('SET_NOTIFICATION_TYPE', payload.type)
    }
  },

  /**
   * @param commit
   * @param payload
   */
  setNotifications({commit}, payload) {
    if(payload.type === chatConfig.notifications.notificationType.notifications) {
      commit('SET_NOTIFICATIONS', payload)
    } else if(payload.type === chatConfig.notifications.notificationType.messages) {
      commit('SET_MESSAGES', payload)
    }
  },

  /**
   * @param commit
   * @param payload
   */
  setProjectsNotifications({commit}, payload) {
    commit('SET_PROJECTS_NOTIFICATIONS', payload)
  },

  /**
   * @param commit
   * @param payload
   */
  setSpacesNotifications({commit}, payload) {
    commit('SET_SPACES_NOTIFICATIONS', payload)
  },

  /**
   * @param commit
   * @param payload
   */
  setPushNotification({commit}, payload) {
    commit('SET_PUSH_NOTIFICATION', payload)
  },

  /**
   * @param commit
   * @param payload
   */
  setNotificationSort({commit}, payload) {
    if(payload.type === chatConfig.notifications.notificationType.notifications) {
      commit('SET_NOTIFICATION_SORT', payload.sort)
    } else if(payload.type === chatConfig.notifications.notificationType.messages) {
      commit('SET_MESSAGES_SORT', payload.sort)
    }
  },

}
