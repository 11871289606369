<template>
  <UiMenu
    offset-y
    :close-on-content-click="false"
    :nudge-bottom="$vuetify.breakpoint.xsOnly ? 0 : 10"
    :zIndex="zIndex"
    v-model="menu"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn
        :color="isActive || menu ? 'accent' : 'gray-60'"
        icon
        width="auto"
        :class="['px-2', openFilterBtnClasses]"
        :plain="isActive || menu"
        v-bind="attrs"
        v-on="on"
      >
        <slot name="icon" :active="isActive" :menu="menu">
          <IconSlidersV width="15" class="icon-transition"/>
        </slot>
        <slot name="sub_icon" :active="isActive" :menu="menu">
          <IconCaretDown v-if="isActive || menu" width="6" :class="['ml-2 icon-transition', {'rotate-180': menu}]"/>
        </slot>
      </UiBtn>
    </template>

    <div class="pt-3 pb-3 pl-3 pr-3">
      <div class="d-flex flex-column flex-sm-row overflow-x-auto accent-scroll">
        <div v-for="(block, idx) in fData" :key="idx+block.title">
          <h6 class="text-body font-weight-semi-bold py-2 pl-2 pr-3 mb-1">{{ block.title }}</h6>

          <v-list nav dense color="gray-10" min-width="180" max-width="220" max-height="220" class="accent-scroll overflow-auto rounded-scroll-track">
            <v-list-item-group
              v-model="block.activeFilters"
              :multiple="block.isMultiple !== 'undefined' ? block.isMultiple : false"
              :mandatory="block.isMandatory !== 'undefined' ? block.isMandatory : false"
            >
              <v-hover v-slot="{ hover }" v-for="(item, idx) in block.list" :key="idx+item.title">
                <v-list-item
                  :value="item.id"
                  :disabled="item.disabled !== 'undefined' ? item.disabled : false"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="ma-0">
                      <UiCheckbox
                        :input-value="active"
                        color="accent-100"
                        :hovered="hover"
                        checkbox-form="square"
                      />
                    </v-list-item-action>
                    <v-list-item-content class="py-1">
                      <v-list-item-title class="text-captions-1">
                        <UiProjectStatus v-if="block.status_indicator" :id="item.id"/>
                        <template v-else>
                          <span class="text-no-wrap text-overflow-ellipsis overflow-hidden">{{ item.title }}</span>
                        </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-hover>
            </v-list-item-group>
          </v-list>

        </div>
      </div>
      <v-divider class="mt-4 mb-2"/>
      <div class="d-flex justify-space-between">
        <div>
          <UiBtn
            v-if="clearBtn"
            color="accent"
            class="pa-0 font-weight-regular text-decoration-underline"
            plain
            @click="clearAll"
          >
            Clear All
          </UiBtn>
        </div>
        <div class="d-flex">
          <UiBtn
            color="transparent"
            class="mr-2"
            width="116px"
            @click="menu = false"
          >
            Cancel
          </UiBtn>
          <UiBtn
            color="accent"
            width="116px"
            @click="save"
          >
            Apply
          </UiBtn>
        </div>
      </div>
    </div>
  </UiMenu>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import UiCheckbox from "@/components/UI/UiCheckbox";
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import {mapGetters} from "vuex";
import {isEqual} from "@/utils/helpers";

export default {
  name: 'UiFilter',
  components: {
    IconSlidersV: () => import('@/components/icons/IconSlidersV'),
    IconCaretDown: () => import('@/components/icons/IconCaretDown'),

    UiProjectStatus,
    UiCheckbox,
    UiMenu,
    UiBtn
  },
  props: {
    openFilterBtnClasses: {
      type: String,
      default: ''
    },
    clearBtn: {
      type: Boolean,
      default: false
    },
    filterData: {
      type: Array,
      // example data
      default: () => [
        {
          type: 'status',
          title: 'Status',
          list: [{
            id: 1,
            title: 'example',
            disabled: false,
          }],
          isMultiple: true,
          isMandatory: false,
          status_indicator: false,
          activeFilters: ['1']
        },
      ]
    },
    zIndex: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      menu: false,
      fData: []
    }
  },
  watch: {
    filterData: {
      immediate: true,
      handler(v) {
        this.fData = JSON.parse(JSON.stringify(v))
      }
    },
    menu: {
      handler(v) {
        if (!v) {
          this.fData = JSON.parse(JSON.stringify(this.filterData))
        }
      }
    },
    getProjectSideDrawerFilters: {
      handler(val) {
        if(!this.getProjectSideDrawer.showProjectSideDrawer) {
          return
        }
        let hasChanges = false
        this.fData.forEach((el) => {
          const selectedFilter = val[el.type.trim()]
          if(Array.isArray(el.activeFilters) && selectedFilter && !isEqual(selectedFilter, el.activeFilters)) {
            el.activeFilters = selectedFilter || []
            hasChanges = true
          } else if(!Array.isArray(el.activeFilters) && selectedFilter !== el.activeFilters) {
            el.activeFilters = selectedFilter
            hasChanges = true
          }
        })
        if(hasChanges) {
          this.$emit('setFilters', this.fData)
        }
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters([
      'getProjectSideDrawerFilters',
      'getProjectSideDrawer',
    ]),
    isActive() {
      return this.filterData.some(el => Array.isArray(el.activeFilters) ? el.activeFilters.length : el.activeFilters)
    },
  },
  methods: {
    save() {
      this.$emit('setFilters', this.fData)
      this.menu = false
    },
    clearAll() {
      this.fData = this.fData.map(el => ({
        ...el,
        activeFilters: Array.isArray(el.activeFilters) ? [] : null
      }));
    },
  },
}
</script>
