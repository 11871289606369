<template>
  <tr>
    <td class="pl-6 cursor-pointer" @click="onLogClick">
      <v-breadcrumbs
        class="breadcrumbs"
        :items="breadcrumbs"
        divider=">"
      />
      <div class="d-flex align-center">
        <v-tooltip v-if="log.user" top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <UiAvatar
              v-bind="attrs" v-on="on"
              class="avatar"
              :src="log.user?.avatar ? log.user.avatar.url : ''"
              :text="log?.user?.first_name"
              size="22"
            />
          </template>
        </v-tooltip>
        <div>
          <div class="d-flex align-center flex-wrap">
            <div class="text-captions-1 gray-80--text mr-1" v-html="logTitle"/>
            <div>{{ formatLogDate(this.log.created_at) }}</div>
          </div>
          <div v-for="(description, idx) in logDescriptions" :key="idx" class="mt-3">
            <div v-if="description.viewType === descriptionViewTypes.text" class="gray-80--text d-flex flex-column justify-center">
              <div v-if="description.old_value" v-html="description.old_value"></div>
              <div v-if="description.old_value && description.new_value">-></div>
              <div v-html="description.new_value"></div>
            </div>
            <div v-if="description.viewType === descriptionViewTypes.text_no_wrap" class="gray-80--text d-flex align-center">
              <div v-if="description.old_value" v-html="description.old_value"></div>
              <div v-if="description.old_value && description.new_value" class="mx-1">-></div>
              <div v-html="description.new_value"></div>
            </div>
            <div v-if="description.viewType === descriptionViewTypes.priority" class="d-flex align-center">
              <div class="d-flex align-center">
                <UiPriorityIcon :size="15" :priority="description.old_value" class="flex-shrink-0 mr-2" />
                {{ $config.tasks.priorityLabels[description.old_value] }}
              </div>
              <div class="mx-2">-></div>
              <div class="d-flex align-center">
                <UiPriorityIcon :size="15" :priority="description.new_value" class="flex-shrink-0 mr-2" />
                {{ $config.tasks.priorityLabels[description.new_value] }}
              </div>
            </div>
            <div v-if="description.viewType === descriptionViewTypes.reminder">
              <div>Reminder</div>
              <div class="d-flex align-center">
                <div>{{description.old_value}}</div>
                <div class="mx-2">-></div>
                <div>{{description.new_value}}</div>
              </div>
            </div>
            <div v-if="description.viewType === descriptionViewTypes.status && (description.old_value?.id || description.old_value?.id === 0) && (description.new_value?.id || description.new_value?.id === 0)" class="d-flex align-center">
              <UiProjectStatus
                v-if="description.old_value?.id || description.old_value?.id === 0"
                class="px-3"
                :id="description.old_value.id"
                :statusType="description.old_value.statusType"
                block
              />
              <div class="mx-2" v-if="description.old_value?.id || description.old_value?.id === 0">-></div>
              <UiProjectStatus
                class="px-3"
                :id="description.new_value?.id || description.new_value?.id === 0"
                :statusType="description.new_value.statusType"
                block
              />
<!--              <div class="d-flex align-center">-->
<!--                <div>{{description.old_value}}</div>-->
<!--                <div class="mx-2">-></div>-->
<!--                <div>{{description.new_value}}</div>-->
<!--              </div>-->
            </div>
            <div v-if="description.viewType === descriptionViewTypes.assignee" class="d-flex align-center">
              <v-tooltip v-if="description.old_value" top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <UiAvatar
                    v-bind="attrs" v-on="on"
                    class="avatar mr-1"
                    :src="description.old_value?.avatar ? description.old_value?.avatar?.url : ''"
                    :text="description.old_value?.first_name"
                    textClass="text-captions"
                    size="18"
                  />
                </template>
              </v-tooltip>
              <span class="font-weight-medium"> {{ description.old_value?.first_name }} {{ description.old_value?.last_name }}</span>
              <div class="mx-2" v-if="description.old_value">-></div>
              <v-tooltip v-if="description.new_value" top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <UiAvatar
                    v-bind="attrs" v-on="on"
                    class="avatar mr-1"
                    :src="description.new_value?.avatar ? description.new_value?.avatar?.url : ''"
                    :text="description.new_value?.first_name"
                    textClass="text-captions"
                    size="18"
                  />
                </template>
              </v-tooltip>
              <span class="font-weight-medium"> {{ description.new_value?.first_name }} {{ description.new_value?.last_name }}</span>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="space-x-1 d-flex pr-2" v-if="logHasFile">
        <UiBtn icon color="gray-60" @click="viewFile(log.new_value.file)">
          <IconEye width="16"/>
        </UiBtn>
        <UiMenu offset-y nudge-bottom="4" nudge-left="8">
          <template #activator="{on, attrs}">
            <UiBtn icon v-bind="attrs" v-on="on" color="gray-60">
              <IconDotsH width="18"/>
            </UiBtn>
          </template>
          <v-list nav dense color="gray-10">
            <v-list-item :disabled="fileLoading"
                         :loading="fileLoading"
                         @click="download(log.new_value.file)">
              <v-list-item-title class="text-captions-1">
                <IconDownload width="14" class="mr-2 gray-60--text"/>
                Download file
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </UiMenu>
      </div>
    </td>
  </tr>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import {isToday, formatDistanceToNowStrict, format, parseISO} from 'date-fns'
import UiAvatar from "@/components/UI/UiAvatar.vue";
import UiProjectStatus from "@/components/UI/UiProjectStatus.vue";
import {formatToHTMLMentionMessage} from "@/utils/helpers"

export default {
  name: "LogHistoryItem",
  components: {
    UiProjectStatus,
    UiAvatar,
    IconEye: () => import('@/components/icons/IconEye'),
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    UiMenu,
    UiBtn,
    UiPriorityIcon: () => import('@/components/icons/IconPriority'),
  },
  props: {
    log: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const actionTypes = this.$config.logHistory.actionTypes;
    const itemTypes = this.$config.logHistory.itemTypes;
    const actionTypesSlugs = this.$config.logHistory.actionTypesSlugs;
    const itemTypesSlugs = this.$config.logHistory.itemTypesSlugs;
    const changeKeysByItemTypes = this.$config.logHistory.changeKeysByItemTypes;
    const changeKeys = this.$config.logHistory.changeKeys

    const descriptionViewTypes = {
      text: 'text',
      text_no_wrap: 'text_no_wrap',
      priority: 'priority',
      reminder: 'reminder',
      status: 'status',
      assignee: 'assignee',
    }
    return {
      fileLoading: false,
      breadcrumbsOrder: [
        {slug: 'project', key: 'project'},
        {slug: 'module', key: 'module'},
        {slug: 'style', key: 'style'},
        {slug: 'asset', key: 'file_id'},
        {slug: 'task', key: 'task', prefix: 'Task '}
      ],
      descriptionViewTypes: descriptionViewTypes,
      logTemplateRules: {
        [itemTypesSlugs.default]: {
          titleTemplate: () => {
            if(this.logActionType === actionTypesSlugs.create) {
              return `<b>${this.userFullName}</b> ${this.logActionType}d a new <b>Project</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.due_date)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Project Due Date</b>`
              }
              return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Project</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Project</b>`
          },
          descriptionTemplateOptions: {
            statusType: 'project'
          },
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create) {
              this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: this.log.breadcrumbs.project?.id},
              });
            } else if(this.logActionType === this.actionTypesSlugs.update) {
              this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: this.log.breadcrumbs.project?.id},
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              console.log('Deleted')
            }
          }
        },
        [itemTypesSlugs.task]: {
          titleTemplate: () => {
            if(this.logActionType === actionTypesSlugs.create) {
              return `<b>${this.userFullName}</b> ${this.logActionType}d a new <b>Task</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.status)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Task status</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.owner)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Task owner</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.assignee)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Task assignee</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.priority)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Task priority</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.due_date)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Task Due Date</b>`
              }
              return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Task</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Task</b>`
          },
          descriptionTemplateOptions: {
            statusType: 'task'
          },
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create || this.logActionType === this.actionTypesSlugs.update) {
              let queryParams = {
                modal_task_id: this.log.breadcrumbs.task?.id,
                style_id: this.log.breadcrumbs.style?.id
              }
              if (this.$route.name === 'ProjectDesign' || this.$route.name === 'DesignViewPage') {
                queryParams = {...this.$route.query, ...queryParams};
              }
              this.$router.replace({
                name: this.$route.name === 'DesignViewPage' ? 'DesignViewPage' : 'ProjectDesign',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: queryParams
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              let queryParams = {
                style_id: this.log.breadcrumbs.style?.id,
                module_id: this.log.breadcrumbs.module?.id,
                gallery_group_id: this.log.breadcrumbs?.gallery_group_id,
                file_id: this.log.breadcrumbs.file?.id,

              }
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: queryParams
              });
            }
          }
        },
        [itemTypesSlugs['sub-task']]: {
          titleTemplate: () => {
            if(this.logActionType === actionTypesSlugs.create) {
              return `<b>${this.userFullName}</b> ${this.logActionType}d a new <b>Sub task</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.status)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Sub task status</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.owner)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Sub task owner</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.assignee)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Sub task assignee</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.priority)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Sub task priority</b>`
              } else if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.due_date)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Sub task Due Date</b>`
              }
              return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Sub task</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Sub task</b>`
          },
          descriptionTemplateOptions: {
            statusType: 'task'
          },
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create || this.logActionType === this.actionTypesSlugs.update) {
              let queryParams = {
                modal_task_id: this.log.breadcrumbs.task?.id,
                style_id: this.log.breadcrumbs.style?.id
              }
              if (this.$route.name === 'ProjectDesign' || this.$route.name === 'DesignViewPage') {
                queryParams = {...this.$route.query, ...queryParams};
              }
              this.$router.replace({
                name: this.$route.name === 'DesignViewPage' ? 'DesignViewPage' : 'ProjectDesign',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: queryParams
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              let queryParams = {
                style_id: this.log.breadcrumbs.style?.id,
                module_id: this.log.breadcrumbs.module?.id,
                gallery_group_id: this.log.breadcrumbs?.gallery_group_id,
                file_id: this.log.breadcrumbs.file?.id,

              }
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: queryParams
              });
            }
          }
        },
        [itemTypesSlugs.file]: {
          titleTemplate: () => {
            if(this.logActionType === actionTypesSlugs.create) {
              if(this.log.new_value?.previous_version && this.log.new_value?.version > 1 && !this.log.old_value) {
                return `<b>${this.userFullName}</b> uploaded a new version <b>asset</b>`
              }
              return `<b>${this.userFullName}</b> uploaded a new <b>asset</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              return `<b>${this.userFullName}</b> ${this.logActionType}d <b>asset</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>asset</b>`
          },
          descriptionTemplateOptions: {
            statusType: 'project'
          },
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create || this.logActionType === this.actionTypesSlugs.update) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: {
                  module_id: this.log.breadcrumbs.module?.id,
                  style_id: this.log.breadcrumbs.style?.id,
                  file_id: this.log.breadcrumbs.file?.id,
                  gallery_group_id: this.log.breadcrumbs?.gallery_group_id,
                }
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: {
                  module_id: this.log.breadcrumbs.module?.id,
                  style_id: this.log.breadcrumbs.style?.id,
                }
              });
            }
          }
        },
        [itemTypesSlugs.comment]: {
          titleTemplate: () => {
            if(this.logActionType === actionTypesSlugs.create) {
              return `<b>${this.userFullName}</b> ${this.logActionType} a new <b>Comment</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              return `<b>${this.userFullName}</b> edited <b>Comment</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Comment</b>`
          },
          descriptionTemplateOptions: {},
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create || this.logActionType === this.actionTypesSlugs.update) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project.id},
                query: {
                  module_id: this.log.breadcrumbs.module?.id,
                  style_id: this.log.breadcrumbs.style?.id,
                  file_id: this.log.breadcrumbs.file?.id,
                  gallery_group_id: this.log.breadcrumbs?.gallery_group_id,
                  comment_id: this.log?.item_id,
                }
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: {
                  module_id: this.log.breadcrumbs.module?.id,
                  style_id: this.log.breadcrumbs.style?.id,
                  file_id: this.log.breadcrumbs.file?.id,
                  gallery_group_id: this.log.breadcrumbs?.gallery_group_id,
                }
              });
            }
          }
        },
        [itemTypesSlugs['sub-comment']]: {
          titleTemplate: () => {
            if(this.logActionType === actionTypesSlugs.create) {
              return `<b>${this.userFullName}</b> ${this.logActionType} a new <b>Comment</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              return `<b>${this.userFullName}</b> edited <b>Comment</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Comment</b>`
          },
          descriptionTemplateOptions: {},
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create || this.logActionType === this.actionTypesSlugs.update) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: {
                  module_id: this.log.breadcrumbs.module?.id,
                  style_id: this.log.breadcrumbs.style?.id,
                  file_id: this.log.breadcrumbs.file?.id,
                  gallery_group_id: this.log.breadcrumbs.gallery_group_id,
                  comment_id: this.log.breadcrumbs.parent_comment?.id,
                }
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: {
                  module_id: this.log.breadcrumbs.module?.id,
                  style_id: this.log.breadcrumbs.style?.id,
                  file_id: this.log.breadcrumbs.file?.id,
                  gallery_group_id: this.log.breadcrumbs?.gallery_group_id,
                }
              });
            }
          }
        },
        [itemTypesSlugs.style]: {
          titleTemplate: () => {

            if(this.logActionType === actionTypesSlugs.create) {
              return `<b>${this.userFullName}</b> uploaded a new <b>Style</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              if(this.updatedKeys.length === 1 && this.updatedKeys.includes(this.changeKeys.title)) {
                return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Style name</b>`
              }
              return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Style</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Style</b>`
          },
          descriptionTemplateOptions: {},
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create || this.logActionType === this.actionTypesSlugs.update) {
              if(this.log.new_value.title) {
                this.$router.replace({
                  name: 'ProjectDesign',
                  params: {project_id: this.log.breadcrumbs?.project?.id},
                });
                return
              }
              let queryParams = {
                style_id: this.log.breadcrumbs.style?.id,
              }
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: queryParams
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: this.log.breadcrumbs.project?.id},
              });
            }
          }
        },
        [itemTypesSlugs.module]: {
          titleTemplate: () => {
            if(this.logActionType === actionTypesSlugs.create) {
              return `<b>${this.userFullName}</b> ${this.logActionType}d a new <b>Module</b>`
            } else if(this.logActionType === actionTypesSlugs.update) {
              return `<b>${this.userFullName}</b> ${this.logActionType}d  <b>Module</b>`
            }
            return `<b>${this.userFullName}</b> ${this.logActionType}d <b>Module</b>`
          },
          descriptionTemplateOptions: {
            statusType: 'module'
          },
          callback: () => {
            if(this.logActionType === this.actionTypesSlugs.create || this.logActionType === this.actionTypesSlugs.update) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: {
                  style_id: this.log.breadcrumbs.style?.id,
                  module_id: this.log.breadcrumbs.module?.id
                }
              });
            } else if(this.logActionType === this.actionTypesSlugs.delete) {
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: this.log.breadcrumbs.project?.id},
                query: {
                  style_id: this.log.breadcrumbs.style?.id,
                }
              });
            }
          }
        },
      },
      descriptionKeysFunctions: {
        [changeKeys.priority]: ({key, old_value, new_value}) => ({
          viewType: this.descriptionViewTypes.priority,
          old_value: old_value || this.log.old_value?.[key],
          new_value: new_value || this.log.new_value?.[key]
        }),
        [changeKeys.reminder]: ({key, old_value, new_value}) => ({
          viewType: this.changeKeys.reminder,
          old_value: old_value || this.formatDate(this.log.old_value?.[key]),
          new_value: new_value || this.formatDate(this.log.new_value?.[key])
        }),
        [changeKeys.status]: ({key, old_value, new_value, statusType}) => ({
          viewType: this.descriptionViewTypes.status,
          old_value: old_value || {id: this.log.old_value?.[key], statusType},
          new_value: new_value || {id: this.log.new_value?.[key], statusType}
        }),
        [changeKeys.title]: ({key, old_value, new_value}) => ({
          viewType: this.descriptionViewTypes.text_no_wrap,
          old_value: old_value || this.log.old_value?.[key],
          new_value: new_value || this.log.new_value?.[key]
        }),
        [changeKeys.name]: ({key, old_value, new_value}) => ({
          viewType: this.descriptionViewTypes.text_no_wrap,
          old_value: old_value || this.log.old_value?.[key],
          new_value: new_value || this.log.new_value?.[key]
        }),
        [changeKeys.description]: ({key, old_value, new_value}) => ({
          viewType: this.descriptionViewTypes.text,
          old_value: old_value || formatToHTMLMentionMessage(this.log.old_value?.[key], this.log.old_value?.tagged_users),
          new_value: new_value || formatToHTMLMentionMessage(this.log.new_value?.[key], this.log.new_value?.tagged_users),
        }),
        [changeKeys.due_date]: ({key, old_value, new_value}) => ({
          viewType: this.descriptionViewTypes.text_no_wrap,
          old_value: old_value || this.formatDate(this.log.old_value?.[key]),
          new_value: new_value || this.formatDate(this.log.new_value?.[key])
        }),
        [changeKeys.assignee]: ({key, old_value, new_value}) => ({
          viewType: this.descriptionViewTypes.assignee,
          old_value: old_value || this.log.old_value?.[key],
          new_value: new_value || this.log.new_value?.[key]
        }),
        [changeKeys.file]: ({old_value, new_value}) => {
          if(this.logActionType === actionTypesSlugs.create && this.log.new_value?.old_file && this.log.new_value?.version > 1 && !this.log.old_value) {
            return {
              viewType: this.descriptionViewTypes.text_no_wrap,
              old_value: old_value || this.log.new_value?.previous_version?.original_name,
              new_value: new_value || this.log.new_value?.file?.original_name,
            }
          }
          return {
            viewType: this.descriptionViewTypes.text_no_wrap,
            old_value: old_value || this.log.old_value?.file?.original_name,
            new_value: new_value || this.log.new_value?.file?.original_name,
          }
        },
      },

      actionTypes,
      itemTypes,
      actionTypesSlugs,
      itemTypesSlugs,
      changeKeysByItemTypes,
      changeKeys,
    }
  },
  computed: {
    formattedDate() {
      const date = parseISO(this.log.created_at)
      return format(date, 'PP - hh:mm aaa')
    },
    logHasFile() {
      return this.log.new_value?.file
    },
    breadcrumbs() {
      return this.breadcrumbsOrder?.reduce((acc, el) => {
        if(el.slug === 'module' && !this.log.breadcrumbs?.[el.key]?.id) {
          return acc
        }

        if(el.slug === 'asset' && this.log.breadcrumbs?.[el.key] && this.log.breadcrumbs?.['task']) {
          return acc
        }
        if(this.log.breadcrumbs?.[el.key]) {
          acc.push({text: `${el.prefix || ''}${this.log.breadcrumbs[el.key]?.title || this.log.breadcrumbs[el.key]}`})
        }
        return acc;
      }, [])
    },
    logItemType() {
      return this.itemTypes?.[this.log?.item_type]
    },
    logActionType() {
      return this.actionTypes?.[this.log?.action_type]
    },
    userFullName() {
      return this.log?.user?.first_name + ' ' + this.log?.user?.last_name
    },
    logTitle() {
      return this.logTemplateRules[this.logItemType]?.titleTemplate()
    },
    availableChangeKeys() {
      return this.changeKeysByItemTypes[this.logItemType]
    },
    updatedKeys() {
      if(!this.log.new_value) {
        return Object.keys(this.log.old_value).filter(el => this.availableChangeKeys.includes(el))
      }
      return Object.keys(this.log.new_value).filter(el => this.availableChangeKeys.includes(el))
    },
    logDescriptions() {
      const options = this.logTemplateRules[this.logItemType]?.descriptionTemplateOptions

      if(this.logActionType === this.actionTypesSlugs.create && (this.logItemType === this.itemTypesSlugs.task || this.logItemType === this.itemTypesSlugs['sub-task'] )) {
        return [this.descriptionKeysFunctions?.['name']({key: 'name', ...options})]
      } else if(this.logActionType === this.actionTypesSlugs.delete) {
        if(this.logItemType === this.itemTypesSlugs.task || this.logItemType === this.itemTypesSlugs['sub-task'] ) {
          return [this.descriptionKeysFunctions?.['name']({key: 'name', ...options})]
        } else if(this.logItemType === this.itemTypesSlugs.file) {
          return [this.descriptionKeysFunctions?.['name']({key: 'name', ...options, new_value: '', old_value: this.log.old_value?.file?.original_name})]
        }
        return [this.descriptionKeysFunctions?.['description']({key: 'description', ...options})]
      }
      if(!this.log.new_value) { // TODO Remove in the future
        return Object.keys(this.log.old_value).reduce((acc, key) => {
          if(!this.descriptionKeysFunctions?.[key] || !this.availableChangeKeys.includes(key)) {
            return acc
          }
          acc.push(this.descriptionKeysFunctions?.[key]({key, ...options}))
          return acc
        }, [])
      }
      return Object.keys(this.log.new_value).reduce((acc, key) => {
        if(!this.descriptionKeysFunctions?.[key] || !this.availableChangeKeys.includes(key)) {
          return acc
        }
        acc.push(this.descriptionKeysFunctions?.[key]({key, ...options}))
        return acc
      }, [])
    },
  },
  methods: {
    viewFile() {
      this.$emit('viewFile', this.log.new_value.file)
    },
    async download(file) {
      if (this.fileLoading || !file) return;
      this.fileLoading = true;

      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name ||  file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    formatDate(date) {
      if(!date) {
        return ''
      }
      return format(new Date(date), 'M/dd/yyyy')
    },
    formatLogDate(date) {
      return format(new Date(date), 'LLLL d - p')
    },
    onLogClick() {
      this.$store.dispatch('toggleProjectSideDrawer', false)
      this.logTemplateRules[this.logItemType].callback({action: this.logActionType})
    },
  }
}
</script>

<style scoped lang="scss">
.file-name-cell{
  max-width: 170px;
}
td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.breadcrumbs {
  padding: 0;
  margin-bottom: 6px;

  :deep(.v-breadcrumbs__divider) {
    font-size: 10px;
    padding: 0 4px;
    color: var(--v-accent-base);
  }
  :deep(.v-breadcrumbs__item) {
    font-size: 10px;
    color: var(--v-accent-base);
  }
}
.avatar {
  margin-right: 10px;
}
</style>
