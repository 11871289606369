const actionTypesSlugs = {
  default: 'default',
  create: 'create',
  update: 'update',
  delete: 'delete',
}

const itemTypesSlugs = {
  default: 'default', // project
  task: 'task',
  'sub-task': 'sub-task',
  file: 'file',
  comment: 'comment',
  'sub-comment': "sub-comment",
  style: 'style',
  module: 'module',
}

const changeKeys = {
  name: 'name',
  priority: 'priority',
  reminder: 'reminder',
  status: 'status',
  title: 'title',
  description: 'description',
  due_date: 'due_date',
  assignee: 'assignee',
  file: 'file',
}

export const logHistoryConfig = {
  logHistory: {
    actionTypesSlugs,
    itemTypesSlugs,
    actionTypes: {
      0: actionTypesSlugs.default, // not used for now
      1: actionTypesSlugs.create,
      2: actionTypesSlugs.update,
      3: actionTypesSlugs.delete,
    },
    itemTypes: {
      0: itemTypesSlugs.default, // project
      1: itemTypesSlugs.task,
      2: itemTypesSlugs['sub-task'],
      3: itemTypesSlugs.file,
      4: itemTypesSlugs.comment,
      5: itemTypesSlugs['sub-comment'],
      6: itemTypesSlugs.style,
      7: itemTypesSlugs.module,
    },
    changeKeys,
    changeKeysByItemTypes: {
      [itemTypesSlugs.default]: [changeKeys.title, changeKeys.status, changeKeys.due_date], // project TODO due_date
      [itemTypesSlugs.task]: [changeKeys.reminder, changeKeys.name, changeKeys.status, changeKeys.assignee, changeKeys.description, changeKeys.due_date, changeKeys.reminder, changeKeys.priority], // TODO owner
      [itemTypesSlugs['sub-task']]: [changeKeys.reminder, changeKeys.name, changeKeys.status, changeKeys.assignee, changeKeys.description, changeKeys.due_date, changeKeys.reminder, changeKeys.priority],
      [itemTypesSlugs.file]: [changeKeys.file, changeKeys.status],
      [itemTypesSlugs.comment]: [changeKeys.description],
      [itemTypesSlugs['sub-comment']]: [changeKeys.description],
      [itemTypesSlugs.style]: [changeKeys.title],
      [itemTypesSlugs.module]: [changeKeys.status, changeKeys.due_date, changeKeys.title],
    },
  }
}
