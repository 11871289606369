export default function auth ({ from, to, next, store }) {

  if(!store.getters['getAuth']){
    return next({
      name: 'WelcomeLanding',
      query: {
        redirect: to.fullPath
      }
    })
  }

  return next()
}
