<template>
  <v-checkbox class="ui-checkbox" :class="[checkboxForm, { hovered }]" off-icon="" on-icon=""
              v-bind="$attrs" v-on="$listeners" :ripple="false">
    <template #default>
      <slot/>
    </template>
    <template #append>
      <slot name="append"/>
    </template>
    <template #prepend>
      <slot name="prepend"/>
    </template>
    <template #label>
      <slot name="label"/>
    </template>
    <template #message>
      <slot name="message"/>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "UiCheckbox",
  inheritAttrs: false,
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    hovered: {
      type: Boolean,
      default: false
    },
    checkboxForm: {
      type: String,
      default: 'circle' // circle, square, radio
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep.v-input.ui-checkbox {

  &.v-input--is-disabled {
    opacity: 0.7;
  }

  .v-input--selection-controls__input {
    width: 17px;
    height: 17px;
    background-size: contain;

    .v-icon {
      display: none;
    }
  }

  &.circle {
    .v-input--selection-controls__input {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Ccircle cx='9.21265' cy='8.90271' r='8.10291' fill='white' stroke='%2381848B'/%3E%3C/svg%3E");
      background-position: center;
    }

    &:not(.v-input--is-label-active) {
      &.hovered,
      &:hover {
        .v-input--selection-controls__input {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Ccircle cx='9.21265' cy='8.90271' r='8.10291' fill='white' stroke='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.21261 1.19556C4.95618 1.19556 1.50549 4.64625 1.50549 8.90268C1.50549 13.1591 4.95618 16.6098 9.21261 16.6098C13.469 16.6098 16.9197 13.1591 16.9197 8.90268C16.9197 4.64625 13.469 1.19556 9.21261 1.19556ZM12.5533 7.59947C12.6148 7.52916 12.6616 7.44725 12.691 7.35857C12.7204 7.26989 12.7318 7.17622 12.7244 7.08309C12.7171 6.98995 12.6912 6.89922 12.6483 6.81624C12.6054 6.73326 12.5463 6.6597 12.4745 6.59989C12.4027 6.54008 12.3197 6.49523 12.2304 6.46797C12.141 6.44072 12.0471 6.43161 11.9542 6.44118C11.8612 6.45076 11.7712 6.47882 11.6892 6.52372C11.6073 6.56862 11.5352 6.62946 11.4771 6.70264L8.46432 10.3173L6.90538 8.75764C6.77324 8.63001 6.59625 8.55939 6.41255 8.56099C6.22884 8.56259 6.05311 8.63627 5.9232 8.76618C5.7933 8.89608 5.71961 9.07182 5.71801 9.25552C5.71642 9.43923 5.78704 9.61621 5.91467 9.74836L8.01661 11.8503C8.08545 11.9191 8.16787 11.9728 8.25862 12.008C8.34937 12.0432 8.44645 12.0591 8.54368 12.0546C8.64091 12.0502 8.73616 12.0256 8.82334 11.9823C8.91053 11.9391 8.98774 11.8781 9.05006 11.8034L12.5533 7.59947Z' fill='%23DCDDDE'/%3E%3C/svg%3E");
          background-position: center;
        }
      }
    }


    &.v-input--is-label-active {
      .v-input--selection-controls__input {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Ccircle cx='9.21265' cy='8.90271' r='8.10291' fill='white' stroke='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.21261 1.19556C4.95618 1.19556 1.50549 4.64624 1.50549 8.90268C1.50549 13.1591 4.95618 16.6098 9.21261 16.6098C13.469 16.6098 16.9197 13.1591 16.9197 8.90268C16.9197 4.64624 13.469 1.19556 9.21261 1.19556ZM12.5533 7.59947C12.6148 7.52916 12.6616 7.44725 12.691 7.35857C12.7204 7.26989 12.7318 7.17622 12.7244 7.08309C12.7171 6.98995 12.6912 6.89922 12.6483 6.81624C12.6054 6.73326 12.5463 6.6597 12.4745 6.59989C12.4027 6.54008 12.3197 6.49523 12.2304 6.46797C12.141 6.44072 12.0471 6.43161 11.9542 6.44118C11.8612 6.45076 11.7712 6.47882 11.6892 6.52372C11.6073 6.56862 11.5352 6.62946 11.4771 6.70264L8.46432 10.3173L6.90538 8.75764C6.77324 8.63001 6.59625 8.55939 6.41255 8.56099C6.22884 8.56259 6.05311 8.63627 5.9232 8.76618C5.7933 8.89608 5.71961 9.07181 5.71801 9.25552C5.71642 9.43923 5.78704 9.61621 5.91467 9.74836L8.01661 11.8503C8.08545 11.9191 8.16787 11.9728 8.25862 12.008C8.34937 12.0432 8.44645 12.0591 8.54368 12.0546C8.64091 12.0502 8.73616 12.0256 8.82334 11.9823C8.91053 11.9391 8.98774 11.8781 9.05006 11.8034L12.5533 7.59947Z' fill='%233121B1'/%3E%3C/svg%3E");
        background-position: center;
      }
      &.v-input--indeterminate {
        .v-input--selection-controls__input {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOS4xMzAyNSIgY3k9IjguNzg4NDUiIHI9IjguMTAyOTEiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS4xMjg5OSAxLjA4MTNDNC44NzI1NiAxLjA4MTMgMS40MjE4OCA0LjUzMTk5IDEuNDIxODggOC43ODg0MkMxLjQyMTg4IDEzLjA0NDkgNC44NzI1NiAxNi40OTU1IDkuMTI4OTkgMTYuNDk1NUMxMy4zODU0IDE2LjQ5NTUgMTYuODM2MSAxMy4wNDQ5IDE2LjgzNjEgOC43ODg0MkMxNi44MzYxIDQuNTMxOTkgMTMuMzg1NCAxLjA4MTMgOS4xMjg5OSAxLjA4MTNaIiBmaWxsPSIjQTU3MkY5Ii8+CjxwYXRoIGQ9Ik01LjkxMDE2IDkuMTg1NTVIMTEuOTEwMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");
          background-position: center;
        }
      }
    }
  }

  &.square {
    .v-input--selection-controls__input {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='18' viewBox='0 0 17 18' fill='none'%3E%3Crect x='0.5' y='0.705811' width='16' height='16' rx='1.5' fill='white' stroke='%2381848B'/%3E%3C/svg%3E");
      background-position: center;
    }

    &:not(.v-input--is-label-active) {
      &.hovered,
      &:hover {
        .v-input--selection-controls__input {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Crect x='1.10645' y='0.705811' width='16' height='16' rx='1.5' fill='%23DCDDDE' stroke='%2381848B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.1076 4.70952C13.4157 4.911 13.5022 5.32415 13.3007 5.63232L8.83428 12.464C8.7249 12.6313 8.54624 12.7407 8.34752 12.762C8.14879 12.7834 7.95097 12.7144 7.80855 12.5742L4.95641 9.76593C4.69405 9.50761 4.69078 9.08551 4.94911 8.82315C5.20743 8.56079 5.62953 8.55752 5.89189 8.81585L8.16384 11.0529L12.1848 4.9027C12.3862 4.59453 12.7994 4.50804 13.1076 4.70952Z' fill='%2381848B'/%3E%3C/svg%3E");
          background-position: center;
        }
      }
    }


    &.v-input--is-label-active {
      .v-input--selection-controls__input {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Crect x='0.712402' y='0.705811' width='16' height='16' rx='1.5' fill='%236A47E9' stroke='%233121B1'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7135 4.70952C13.0217 4.911 13.1082 5.32415 12.9067 5.63232L8.44024 12.464C8.33086 12.6313 8.1522 12.7407 7.95348 12.762C7.75475 12.7834 7.55693 12.7144 7.4145 12.5742L4.56237 9.76593C4.30001 9.50761 4.29674 9.08551 4.55507 8.82315C4.81339 8.56079 5.23549 8.55752 5.49785 8.81585L7.7698 11.0529L11.7907 4.9027C11.9922 4.59453 12.4053 4.50804 12.7135 4.70952Z' fill='white'/%3E%3C/svg%3E");
        background-position: center;
      }
    }
  }

  &.radio {
    .v-input--selection-controls__input {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Ccircle cx='9.17127' cy='8.80872' r='8.10291' fill='white' stroke='%2381848B'/%3E%3C/svg%3E");
      background-position: center;
    }

    &:not(.v-input--is-label-active) {
      &.hovered,
      &:hover {
        .v-input--selection-controls__input {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Ccircle cx='9.17127' cy='8.80872' r='8.10291' fill='white' stroke='%2381848B'/%3E%3Ccircle cx='9.17125' cy='8.8087' r='5.04527' fill='%23DCDDDE' stroke='%2381848B'/%3E%3C/svg%3E");
          background-position: center;
        }
      }
    }


    &.v-input--is-label-active {
      .v-input--selection-controls__input {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Ccircle cx='9.17127' cy='8.80872' r='8.10291' fill='white' stroke='%2381848B'/%3E%3Ccircle cx='9.17125' cy='8.8087' r='5.04527' fill='%236A47E9' stroke='%233121B1'/%3E%3C/svg%3E");
        background-position: center;
      }
    }
  }

  .v-label {
    font-size: 12px;
  }
}
</style>
